<template>
    <div id="payments">
    <div class="panel-body">
        <div class="radio">
            <label>
                <input type="radio" name="payment_type" v-model="type" value="cc">

                <strong>Sicher</strong> bezahlen mit Kreditkarte
            </label>
            <div id="stripe" ref="container" :class="{'has-error': submitted && !paymentValid}"></div>

            <div class="alert alert-danger" v-show="submitted && !paymentValid">
                Bitte prüfen Sie ihre Angaben zur Kreditkarte
            </div>
        </div>
    </div>
    <div class="panel-body border-top">
        <div class="radio">
            <label>
                <input type="radio" name="payment_type" v-model="type" value="sofort">
                <img src="https://cdn.klarna.com/1.0/shared/image/generic/badge/de_de/checkout/short-blue.png?width=400" alt="">
                <hr>
                Sie werden nach der Bestellung auf die Seiten von Klarna weitergeleitet
                um dort die Bezahlung abzuwickeln.
            </label>
        </div>
    </div>
        <input type="hidden" :name="name" v-model="token" />
    </div>
</template>
<script>
export default {
    props: ['stripeKey', 'name', 'submitButton', 'formName'],
    data() {
        return {
            stripe: null,
            type: null,
            card: null,
            form: null,
            paymentValid: false,
            submitted: false,
            token: ''
        }
    },
    created() {
        this.stripe = Stripe(this.stripeKey);
        const elements = this.stripe.elements();
        this.card = elements.create('card');
        this.card.on('change', (event) => {
            if (this.type !== 'cc') this.type = 'cc';

            if (event.complete) {
                this.error = null;
                this.paymentValid = true;
            } else if (event.error) {
                this.error = event.error.message;
                this.paymentValid = false;
            }
        });
    },
    mounted() {
        this.card.mount(this.$refs.container);
        this.form = $("form[name='" + this.formName + "']").on('submit', this.submit);
    },
    watch: {
        type(newType, old) {
            if (newType === 'sofort') {
                this.token = newType;
            }
        }
    },
    methods: {
        async createSource() {
           const {source, error} = await this.stripe.createSource(this.card);
           if (error) {
               console.log(error, this.card);
               throw error;
           }

           return source;
        },
        submit(e) {
            if (this.type === 'cc') {
                this.submitted = true;
                e.preventDefault();
                if (this.paymentValid) {
                    this.createSource().then((source) => {
                        this.token = source.id;
                        this.$nextTick(() => {
                            e.target.submit();
                        })
                    });
                }
            } else {
                this.token = this.type;
            }
        }
    }
}
</script>
<style lang="scss">
    #payments {

        #stripe {
            margin-left: 20px;
            margin-top: 5px;
            padding: 10px;
            border: 1px solid lightgrey;

            &.has-error {
                border-color: #a94442;
            }
        }

        .alert-danger {
            margin-left: 20px;
            margin-top: 5px;
        }
    }
</style>
