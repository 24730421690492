import Vue from 'vue';
import Invoice from './components/Invoice.vue';
import DashboardChart from './components/Admin/DashboardChart'
import DynamicFormComponent from './components/DynamicForm';
import PaymentComponent from './components/Payment';
import 'babel-polyfill';
import ClientForm from './jquery/ClientForm';

import './jquery/DateRange';

// Outdated Browser scss
import 'outdatedbrowser/outdatedbrowser/outdatedbrowser.scss';


Vue.filter('formatDate', function(value) {
    if (value) {
        if (value instanceof Date || value instanceof moment) {
            return value.format('DD.MM.YYYY');
        } else {
            return moment(String(value)).format('MM/DD/YYYY hh:mm');
        }
    }
});

//Vue.component('dashboard-chart', DashboardChart);

/**
 * Init from JS
 * @param selector
 * @param data
 */
let app = function(selector, data) {
    const dateFields = ['startDate', 'dueDate', 'fromDate', 'toDate'];

    // convert some dates to moment() before passing
    _.forEach(data.invoice, (value, key, data) => {
        //console.log(data, value, key);
        if(_.includes(dateFields, key)) {
            data[key] = moment(value);
        }
    });

    return new Vue({
        el: selector,
        render: h => h(Invoice, {
            props: data
        })
    });
};

/**
 * Kleinere Formular-Helfer
 */
$(function() {

// Client Move out
const movedOutInput = $('#client_movedOut');
const movedOutCheck = $('#client_archived');

movedOutCheck.on('change', function(e) {
    const movedOut = e.target.checked;

    if (movedOut) {
        movedOutInput.attr({'disabled':  false, required: true});
    } else {
        movedOutInput.attr({'disabled': true, required: false});
    }
});
movedOutCheck.trigger('change');

});

const DashboardChartClass = Vue.extend(DashboardChart);
const DynamicForm = Vue.extend(DynamicFormComponent);
const Payment = Vue.extend(PaymentComponent);
export {ClientForm};
export {DashboardChartClass, DynamicForm, Payment};
export default app;
