<template>
    <select>
        <slot></slot>
    </select>
</template>

<script>
    import EventBus from '../EventBus';
    export default {
        props: ['options', 'value'],
        mounted: function () {
            var vm = this
            $(this.$el)
            // init select2
                /*.select2({
                    data: {results: this.options, text: 'name' },
                    id: 'id'
                })*/
                .select2({ data: this.options, placeholder: 'Bitte wählen' })
                .val(this.value)
                .trigger('change')
                // emit event on change.
                .on('change', function () {
                    vm.$emit('input', this.value)
                })

            EventBus.$on('formulaCreated', () => $(this.$el).trigger('change'));
            EventBus.$on('productCreated', () => $(this.$el).trigger('change'));
        },
        watch: {
            value: function (value) {
                // update value
                $(this.$el).val(value).trigger('change');
                //console.log('value changed', value);
                //$(this.$el).select2("val", value);
            },
            options: function (options) {
                // update options
                $(this.$el).select2({ data: options })
            }
        },
        destroyed: function () {
            $(this.$el).off().select2('destroy')
        }
    }
</script>
