<template>
    <div>
        <div class="col-md-12">
            <button type="button" @click="items.push({})" class="btn btn-info">
                <i class="fa fa-plus"></i>
                Weitere Wohnung in diesem Objekt anlegen
            </button>
        </div>
        <div class="clearfix"></div>
        <transition-group name="fade">
            <div class="col-md-6 col-lg-3" v-for="item, index in items" :key="index">
                <h3>Wohnung #{{ index + 2 + formCount }}
                    <button type="button" @click="items.splice(index, 1)" class="btn btn-sm btn-link pull-right">
                        <i class="fa fa-remove"></i>
                        entfernen
                    </button>
                </h3>
                <div v-html="prototypeFor(index)"></div>

            </div>
        </transition-group>
    </div>
</template>

<script>
export default {
    props: ['formCount', 'container', 'prototype'],
    data() {
        return {
            items: []
        }
    },
    computed: {
        index() {
            return this.formCount + this.items.length;
        }
    },
    methods: {
        prototypeFor(index) {
            return this.prototype
                .replace(/__name__/g, this.formCount + index);
        }
    }
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
