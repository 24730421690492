import 'daterangepicker';
import 'daterangepicker/daterangepicker.css';

$(() => {
    const submit = function(start, end) {
        // !! start and end are switched! This is correct
        window.location = $('#datepicker').data('url')
            .replace('_from_', start.format('YYYY-MM-DD'))
            .replace('_to_', end.format('YYYY-MM-DD'));
    }


    $('#datepicker').daterangepicker({
        ranges: {
            'Dieser Monat': [moment().startOf('month'), moment().endOf('month')],
            'Letzter Monat': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
            '3 Monate': [
                moment().subtract(3, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month')
            ],
            '6 Monate': [
                moment().subtract(6, 'month').startOf('month'),
                moment().subtract(1, 'month').endOf('month')
            ],
            '12 Monate': [
                moment().subtract(11, 'month').startOf('month'),
                moment().endOf('month'),
            ],
            '24 Monate': [
                moment().subtract(23, 'month').startOf('month'),
                moment().endOf('month'),
            ],
            'Gesamter Zeitraum': [
                moment('2016-04-27'),
                moment()
            ]
        },
        "applyButtonClasses": "btn-success",
        "locale": {
            //"format": "MM/DD/YYYY",
            "format": "DD.MM.YYYY",
            "separator": " - ",
            "applyLabel": "Anwenden",
            "cancelLabel": "Abbrechen",
            "fromLabel": "Vom",
            "toLabel": "Bis",
            "customRangeLabel": "Eigener Zeitraum",
            "weekLabel": "W",
            "daysOfWeek": [
                "So",
                "Mo",
                "Di",
                "Mi",
                "Do",
                "Fr",
                "Sa"
            ],
            "monthNames": [
                "Januar",
                "Februar",
                "März",
                "April",
                "Mai",
                "Juni",
                "Juli",
                "August",
                "September",
                "Oktober",
                "November",
                "Dezember"
            ],
            "firstDay": 1
        },
    }, submit);


})
