<template>

        <div class="col-md-12 col-lg-12">
            <table class="table">
                <thead>
                <tr>
                    <th class="hidden">#</th>
                    <th class="col-md-2">Nebenkosten</th>
                    <th class="xs-small"></th>
                    <th class="xs-small"></th>
                    <th class="col-md-1">Gesamtkosten</th>
                    <th class="col-md-1">Umlageschlüssel</th>
                    <th class="xs-small"></th>
                    <th class="col-md-1">Gesamtfaktor</th>
                    <th class="col-md-1">Ihr Anteil</th>
                    <th class="col-md-1">Tage</th>
                    <th class="col-md-1">Zeitanteil</th>
                    <th class="col-md-1 text-right">Summe</th>
                </tr>
                </thead>

                <tbody class="rayParent">
                <product v-for="(item, index) in value.invoiceProducts" :key="index" :index="index" ref="products"
                         :globalDayshare="dayShare"
                         :globalDays="days"
                         :products="products"
                         :formulas="formulas"
                         :invoice="value"
                         :defaultShares="defaultShares"
                         v-model="value.invoiceProducts[index]"
                         v-on:updateSum="updateTotal($event, item)"
                         v-on:removeRow="removeRow(index)"
                         :deletable="index !== 0"></product>
                </tbody>

                <tfoot>
                <tr>
                    <td colspan="6">
                        <div class="col-md-12 col-lg-6">
                            <button type="button" class="btn btn-primary btn-lg" @click="addRow()"><i class="fa fa-plus"></i> Neuen Posten hinzufügen</button>
                        </div>
                    </td>

                    <td colspan="2">
                        <h3 class="pull-right top10">Gesamtsumme</h3>
                        <p class="pull-right" v-show="toPay >= 0">Errechnete Nachzahlung</p>
                        <p class="pull-right" v-show="toPay < 0">Errechnete Rückzahlung</p>
                    </td>

                    <td colspan="3">
                        <h3 class="top10">
                            <span class="rayTotal">{{ total }}</span>
                            <span class="rayCurrency">&euro;</span>
                        </h3>
                        <p>
                            {{ Math.abs(toPay).toFixed(2) }} <span class="rayCurrency">&euro;</span>
                        </p>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>

</template>

<script>
    import Product from './Product.vue';
    export default {
        name: 'ProductTable',
        components: {
            Product
        },
        props: {
            value: { required: true },
            products: { type: Array, required: true },
            formulas: { type: Array, required: true },
            client: { type: Object },
            dayShare: { type: [Number, String] },
            days:     { type: [Number, String] }
        },
        data() {
            return {
                totalCosts: 0
            }
        },
        created() {
            if (this.value.invoiceProducts.length === 0) {
                this.addRow();
            }
        },
        computed: {
            isValid() {
                let valid = true;
                this.$refs.products.forEach(product => {
                    if (product.isValid === false) valid = false;
                });

                return valid;
            },
            /**
             * Gesamtsumme der Produkte
             * @return {string}
             */
            total() {
                let sum = 0;
                for(let i in this.value.invoiceProducts) {
                    sum += this.value.invoiceProducts[i].amount;
                }

                return sum.toFixed(2);
            },
            toPay() {
                let discount = this.value.discount;
                if (_.isString(discount)) {
                    discount = this.value.discount.replace(/,/g, '.');
                }

                if (_.isNull(discount)) {
                    discount = 0;
                }

                return (this.total - parseFloat(discount));
            },
            /**
             * Array mit default shares und totales
             * $formulaId => [totalShare, share]
             *
             * @return Object
             */
            defaultShares() {
                const defaultPeople = (this.client.lastnameTwo ? 2 : 1);
                return {
                    1: [this.client.object.totalshare, this.client.object.share],
                    2: [this.client.object.totalsize, this.client.object.size],
                    3: [this.client.object.livingQuarters, 1],
                    4: [defaultPeople, defaultPeople],
                }
            }
        },
        methods: {
            addRow: function() {
                // Anzahl bewohner
                let newInvoice = Object.assign({}, this.value);
                    newInvoice.invoiceProducts.push({
                        amount: 0,
                        totalcosts: 0,
                        product: this.products[0].id,
                        days: this.days,
                        dayshare: this.dayShare,
                        formula: this.formulas[0].id,
                        //shares: buildShares(false),
                        //totalShares: buildShares(true)
                    });
                this.$emit('input', newInvoice);
            },
            removeRow: function(index) {
                let newInvoice = Object.assign({}, this.value);
                    newInvoice.invoiceProducts.splice(index, 1);
                this.$emit('input', newInvoice);
            },
            updateTotal: function(sum, item) {
                item.amount = sum;
            }
        }
    }
</script>
