<template>
    <div id="invoice-table">
    <div class="col-md-6 col-lg-3">
        <div class="form-group">
            <label for="client">Wohnung und Mieter</label>
            <select2 :name="fieldName('client')" class="form-control required" v-model="data.client">
                <option>Bitte wählen</option>
                <option v-for="(client, index) in validClients" :key="index" :value="client.id">
                    {{client.object.address}} - {{client.object.location}} - {{client.lastname}}
                </option>
            </select2>
        </div>
    </div>


    <div class="col-md-3 col-lg-3">
        <div class="form-group">
            <label for="moving_in">Einzugsdatum</label>
            <input type="text" name="moving_in" class="form-control" autocomplete="off" :value="moveDateDisplay" readonly>
        </div>
    </div>

    <div class="col-md-3 col-lg-3">
        <div class="form-group" :class="{'has-error': !fromDateValid}">
            <label for="fromDate">Abrechnungszeitraum von</label>
            <datepicker :name="fieldName('fromDate')" :disabled="!data.client" class="form-control required" v-model="data.fromDate" format="dd.mm.yyyy" data-toggle="tooltip" data-placement="bottom" title="Der Abrechnungszeitraum muss mit der Hausgeldabrechnung übereinstimmen"></datepicker>
        </div>
    </div>

    <div class="col-md-3 col-lg-3">
        <div class="form-group" :class="{'has-error': !fromDateValid}">
            <label for="toDate">bis</label>
            <datepicker :name="fieldName('toDate')" class="form-control required" autocomplete="off" :disabled="!data.client" v-model="data.toDate" data-toggle="tooltip" data-placement="bottom" title="Der Abrechnungszeitraum muss mit der Hausgeldabrechnung übereinstimmen"></datepicker>
        </div>
    </div>
    <div class="clearfix"></div>

    <div class="col-md-3 col-lg-3">
        <div class="form-group">
            <label for="date">Datum</label>
            <input type="text" :name="fieldName('startDate')" :value="data.startDate | formatDate" class="form-control datepicker required" autocomplete="off">
        </div>
    </div>

    <div class="col-md-3 col-lg-3">
        <div class="form-group">
            <label for="end">fällig</label>
            <input type="text" :name="fieldName('dueDate')" :value="data.dueDate | formatDate" class="form-control datepicker required" autocomplete="off">
        </div>
    </div>

    <div class="col-md-3 col-lg-3">
        <div class="form-group">
            <label>Zeitanteil (Tage)</label>
            <input type="text" :value="dayShare + ' (' + days + ')'" class="form-control" disabled autocomplete="off">
        </div>
    </div>

    <div class="col-md-3 col-lg-3 pull-right">
        <div class="form-group">
            <label for="invoiceDiscount" class="show">Summe der NK-Vorauszahlungen</label>
            <div class="input-group">
                <input type="number" step="0.01" v-model="data.discount" :name="fieldName('discount')" class="form-control" autocomplete="off" data-toggle="tooltip" data-placement="bottom" title="Wir empfehlen Ihnen die tatsächlichen Zahlungeingänge auf den Kontoauszügen zu überprüfen">
                <div class="input-group-addon">€</div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>

        <div class="form-group col-md-12">
            <div class="col-md-12 alert alert-error" role="alert" v-show="!fromDateValid">
                <strong>Achtung!</strong> Das Einzugsdatum des Mieters liegt nicht im Abrechungszeitraum. Die Abrechnung könnte ungewollte Ergebnisse liefern.
            </div>
        </div>

        <product-table
                v-if="client"
                ref="productTable"
                v-model="data"
                :client="client"
                :products="products"
                :dayShare="dayShare"
                :days="days"
                :discount="data.discount"
                :formulas="formulas" />

        <div class="form-group col-md-12 top20" v-show="!client">
            <label class="infolabel"><strong><i class="fa fa-arrow-up"></i> Bitte wählen Sie zunächst Wohnung und Mieter aus.</strong></label>
        </div>

        <div class="form-group col-md-12 top20">
            <button type="submit" :disabled="!submitValid" class="btn btn-success btn-lg"><i class="fa fa-save"></i> Abrechnung speichern</button>
        </div>

        <formula-modal id="rayCreateFormula" href="/formula/new" title="Einen neuen Abrechnungschlüssel erstellen" @success="addFormula"></formula-modal>
        <formula-modal id="rayCreateProduct" href="/settings/products/create" title="Einen neuen Posten erstellen" @success="addProduct"></formula-modal>
    </div>
</template>

<script>
    import Select2 from './Select2.vue';
    import Datepicker from './Datepicker.vue';
    import ProductTable from "./ProductTable.vue";
    import FormulaModal from './FormulaModal'
    import EventBus from '../EventBus'

    const DATE_FORMAT = 'YYYY-MM-DD';

    export default {
        props: {
            clients: {required: true},
            products: {type: Array, required: true},
            formulas: { required: true, type: Array },

            // Leeres Invoice Objekt
            invoice: {
                default() {
                    return {
                        client: null,
                        discount: null,
                        startDate: moment(),
                        dueDate: moment().add(30, 'days'),
                        fromDate: moment().subtract(1, 'years').startOf('year'),
                        toDate: moment().subtract(1, 'years').endOf('year'),
                        invoiceProducts: []
                    }
                }
            },
            // Name des Formulars
            name: { required: true, type: String},
            productName: { required: true, type: String},
            errors: {required: false, default() { return {} }}
        },
        components: {
            ProductTable,
            Select2,
            Datepicker,
            FormulaModal
        },
        data() {
            return {
                data: this.invoice,
                rowsValid: false
            }
        },
        computed: {
            client() {
                return _.find(this.clients, {'id': Number(this.data.client)});
            },
            validClients() {
                return _.filter(this.clients, (c) => c.object)
            },
            moveInDate() {
                if (!this.client) {
                    return false;
                }

                return moment(this.client.movingIn, DATE_FORMAT);
            },
            moveOutDate() {
                if (!this.client || this.client.movedOut === null) {
                    return false;
                }

                const date = moment(this.client.movedOut, DATE_FORMAT);
                return (date.isAfter(this.moveInDate) ? date : false);
            },
            moveDateDisplay() {
               let display = '';
               if (this.moveInDate) {
                   display = this.moveInDate.format('DD.MM.YYYY');
               }

               if (this.moveOutDate) {
                   display = display + ' bis ' + this.moveOutDate.format('DD.MM.YYYY');
               }

               return display;
            },

            //Berechnung des Zeitanteils
            dayShare() {
                if (!this.client) return '';

                let start,end;
                if (this.moveInDate.isBefore(this.data.fromDate)) {
                    start = moment(this.data.fromDate);
                } else {
                    start = this.moveInDate;
                }

                if (this.moveOutDate && this.moveOutDate.isBefore(this.data.toDate)) {
                    end = this.moveOutDate;
                } else {
                    end = moment(this.data.toDate);
                }

                return Math.max(end.diff(start, 'days') + 1, 0);
            },

            // Berechnung des Abrechnungszeitraums
            days() {
                let start = moment(this.data.fromDate),
                    end   = moment(this.data.toDate);

                return end.diff(start, 'days') + 1;
            },

            /**
             * Is the move in Date Valid?
             * @return {computed.moveInDate|*}
             */
            fromDateValid() {
                if (!this.client) return true;

                return this.dayShare > 0;
            },

            /**
             * Can the form be submitted?
             * @return {computed.fromDateValid|computed.client}
             */
            submitValid()
            {
                return this.fromDateValid && this.client;
            }
        },
        methods: {
            /**
             * Generiert den Feldnamen für das Formular
             * @param name
             * @return {string}
             */
            fieldName(name) {
                return `${this.name}[${name}]`;
            },
            addFormula(formula) {
                this.formulas.push(formula);
                EventBus.$emit('formulaCreated', formula);
            },
            addProduct(product) {
                this.products.push(product);
                EventBus.$emit('productCreated', product);
            }
        }
    }
</script>
