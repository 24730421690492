(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("_"), require("vue"), require("moment"), require("jQuery"));
	else if(typeof define === 'function' && define.amd)
		define(["_", , "moment", "jQuery"], factory);
	else if(typeof exports === 'object')
		exports["frontend"] = factory(require("_"), require("vue"), require("moment"), require("jQuery"));
	else
		root["frontend"] = factory(root["_"], root["Vue"], root["moment"], root["jQuery"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__60bb__, __WEBPACK_EXTERNAL_MODULE__8bbf__, __WEBPACK_EXTERNAL_MODULE_c32d__, __WEBPACK_EXTERNAL_MODULE_c5e1__) {
return 