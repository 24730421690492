<template>
    <canvas v-once ref="canvas" id="" style="height:400px">
    </canvas>
</template>
<script>
export default {
    props: {
        userData: { type: [Array, Object], required: true },
        invoiceData: { type: [Array, Object], required: true },
        repeaterData: { type: [Array, Object], required: true },
        creatorData: { type: [Array, Object], required: true },
    },
    data() {
        return {
            chart: null,
            colors: ['#f39c12', '#00c0ef', '#00a65a', '#d2d6de'],

            /**
             * Chart Options
             */
            optionsLine: {
                scales: {
                    yAxes: [{
                        id: 1,
                        ticks: {
                            beginAtZero: true,
                            maxTicksLimit: 10
                        }
                    }],
                        xAxes: [{
                        type: 'time',
                        time: { unit: 'month'}
                    }]
                }
            },
            optionsBar: {
                legend: { display: false },
                scales: {
                    yAxes: [{
                        ticks: {beginAtZero: true}
                    }]
                }
            }
        }
    },
    computed: {
        isSingle() { return Object.keys(this.userData).length === 1 },
        datasets() {
            if (this.isSingle) {
                return [{
                    backgroundColor: this.colors,
                    label: false,
                    data: [
                        this.getDataRow(this.userData),
                        this.getDataRow(this.invoiceData),
                        this.getDataRow(this.creatorData),
                        this.getDataRow(this.repeaterData),
                    ]
                }]
            }

            const defaultDataSet = {
                fill: false,
                spanGaps: true
            }

            const datasets = [];
            datasets.push({...defaultDataSet, ...{
                label: "Registriete Benutzer",
                data: this.getDataRow(this.userData),
                borderColor: this.colors[0],
                backgroundColor: this.colors[0]
            }});
            datasets.push({...defaultDataSet, ...{
                label: 'Erstellte Abrechnungen',
                data: this.getDataRow(this.invoiceData),
                borderColor: this.colors[1],
                backgroundColor: this.colors[1]
            }});
            datasets.push({...defaultDataSet, ...{
                label: 'Ersteller',
                data: this.getDataRow(this.creatorData),
                borderColor: this.colors[2],
                backgroundColor: this.colors[2]
            }});
            datasets.push({...defaultDataSet, ...{
                label: 'Wiederholer',
                data: this.getDataRow(this.repeaterData),
                borderColor: this.colors[3],
                backgroundColor: this.colors[3]
            }});

            return datasets;
        }
    },
    methods: {
        getDataRow: input => {
            const keys = Object.keys(input);
            if (keys.length === 1) {
                 return Number(input[keys[0]][0].count);
            }

            const data = [];
            for (let [key, datas] of Object.entries(input)) {
                data.push({
                    x: moment(datas[0].year + '-' + datas[0].month, 'YYYY-MM'),
                    y: datas[0].count
                })
            }

            return data;
        }
    },
    mounted() {
        const context = this.$refs.canvas.getContext('2d');

        if (this.isSingle) {
            this.chart = new Chart(context, {
                type: 'bar',
                data: {
                    labels: ['Registrierte Benutzer', 'Erstelle Abrechnungen', 'Ersteller', 'Wiederholer'],
                    datasets: this.datasets,

                },
                options: this.optionsBar
            });
        } else {
            this.chart = new Chart(context, {
                type: 'line',
                data: {datasets: this.datasets},
                options: this.optionsLine
            });
        }

    }
}
</script>
