<template>
    <input type="text" />
</template>

<script>
export default {
    props: {
        value: { default: () => new Date()},
        format: { default: 'dd.mm.yyyy'}
    },
    mounted() {
        let vm = this;

        $(this.$el)
            .datepicker({
                format: this.format
            })
            //.val(this.date)
            //.trigger('change')
            .datepicker('setValue', this.value)
            .on('changeDate', function (e) {
                $(this).datepicker('hide');
                vm.$emit('input', e.date);
            });
    },
    watch: {
        date(newDate) {
            $(this.$el).datepicker('setValue', newDate);
        }
    }
}
</script>
