/**
 * Update Address on Object change in client form
 *
 * @param data
 * @param id
 */
export default function({data, id}) {
    const clientSelect = $('#' + id);
    const form = clientSelect.parents('form');

    const animation = [
        { backgroundColor: '#FFFADB', borderColor: '#BC6015'},
        { backgroundColor: '#FFF', borderColor: '#CCC'}
    ]
    const animationTiming = {
        duration: 1000,
        iterations: 1,

    }

    const fillField = (field, value) => {
        const $field = form.find('#' + field, );
        if ($field.val() !== value) {
            $field.val(value);

            const el = $field.get(0);
            if (typeof el.animate === 'function') {
                el.animate(animation, animationTiming);
            }
        }
    }

    const fillForms = ({address, zip, city}) => {
        fillField('client_address', address);
        fillField('client_zip', zip);
        fillField('client_city', city);
    }

    const clearForms = () => fillForms({address: '', zip: '', city: ''});

    clientSelect.change(e => {
        let newId = e.target.value;
        if (data[newId]) {
            fillForms(data[newId]);
        } else {
            clearForms();
        }
    })
}
