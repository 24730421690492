<template>

    <tr :class="{invalid: !isValid}">
        <td>
            <select2 v-model="data.product" class="form-control" :name="fieldName('product')" :class="{invalid: value.product === null}">
                <option></option>
                <option v-for="(p, index) in products" :key="index" :value="p.id">
                    {{ p.name }}
                </option>
            </select2>
            <span class="text-danger" v-if="errors.product" v-html="errors.product"></span>
        </td>
        <td>
            <button type="button" title="neue Nebenkostenart erstellen" class="btn btn-default" data-toggle="modal" @click="registerProductCreate"
                    data-target="#rayCreateProduct"><i class="fa fa-plus"></i>
            </button>
        </td>

        <td>
            <button type="button" class="btn btn-default removeClone" :class="{disabled: !deletable}" @click="$emit('removeRow')"><i
                    class="fa fa-trash-o"></i></button>
        </td>

        <td>
            <vue-numeric :minus="true" v-bind:precision="2" separator="." class="form-control  costs required no-line"
                         autocomplete="off" v-model="data.totalcosts"></vue-numeric>
        </td>

        <td>
            <select :name="fieldName('formula')" class="form-control" v-model="data.formula">
                <option :key="i" :value="f.id" v-for="(f, i) in formulas">{{ f.name }}</option>
            </select>
        </td>

        <td>
            <button @click="formulaCreated = true" type="button" title="Neuen Umlageschlüssel erstellen"
                    class="btn btn-default" data-toggle="modal" data-target="#rayCreateFormula"><i
                    class="fa fa-plus"></i></button>
        </td>

        <td>
            <vue-numeric v-bind:precision="2" separator="." class="form-control  eigentumsanteile required no-line"
                         autocomplete="off" data-toggle="tooltip" data-placement="bottom"
                         title="Die Gesamteigentumsanteile finden Sie in Ihrer Verwaltungsabrechnung"
                         v-model="currentTotalShare"></vue-numeric>

        </td>

        <td>
            <vue-numeric v-bind:precision="2" separator="." class="form-control  eigentumsanteile required no-line"
                         autocomplete="off" data-toggle="tooltip" data-placement="bottom"
                         title="Ihren Eigentumsanteil finden Sie in Ihrer Verwaltungsabrechnung"
                         v-model="currentShare"></vue-numeric>
        </td>

        <td>
            <vue-numeric v-bind:precision="0" separator="." :class="{default: data.days === globalDays, invalid: data.days === 0}"
                         :name="fieldName('days')" class="form-control required no-line" autocomplete="off"
                         v-model="data.days"></vue-numeric>
        </td>

        <td>
            <vue-numeric v-bind:precision="0" separator="." :class="{default: data.dayshare === globalDayshare, invalid: data.dayshare === 0}"
                         :name="fieldName('dayshare')" class="form-control required no-line" autocomplete="off"
                         v-model="data.dayshare"></vue-numeric>
        </td>

        <td>
            <h4 class="pull-right">
                <span class="raySubTotal">{{ formatCurrency(subTotal) }} &euro;</span>
                <input type="hidden" :name="fieldName('totalcosts')" v-model="data.totalcosts"/>
                <input type="hidden" :name="fieldName('currentShare')" :value="currentShare"/>
                <input type="hidden" :name="fieldName('currentTotalShare')" :value="currentTotalShare"/>
            </h4>
        </td>

    </tr>
</template>

<script>
    import Select2 from './Select2.vue'
    import EventBus from '../EventBus'
    import VueNumeric from 'vue-numeric'

    export default {
        name: 'Product',
        props: [
            'invoice',
            'value',
            'defaultShares',
            'globalDayshare',
            'globalDays',
            'formulas',
            'products',
            'index',
            'deletable',
        ],
        components: {
            Select2,
            VueNumeric
        },
        data() {
            return {
                data: this.value,
                localShares: [],
                localTotalShares: [],
                // did the user created a formula in this product?
                formulaCreated: false,
                errors: {},
            }
        },
        computed: {
            /**
             * Do the actual calculation
             * @return Number
             */
            subTotal: function () {
                let shareFactor = parseFloat(this.currentShare) / parseFloat(this.currentTotalShare)
                let timeFactor = parseInt(this.data.dayshare) / parseInt(this.data.days)
                let sub = parseFloat(this.data.totalcosts) * shareFactor * timeFactor

                this.$emit('updateSum', sub)
                return sub
            },
            currentShare: {
                get() {
                    const id = this.formula.id;
                    // has local value?
                    if (this.localShares[id]) {
                        return this.localShares[id];
                    }

                    if (this.defaultShares[id]) {
                        return this.defaultShares[id][1];
                    }

                    // default
                    if (this.formula.currentShare) {
                        return this.formula.currentShare;
                    }

                    return this.formula.defaultShare;
                },
                set(value) {
                    if (value > 0 && this.formula)
                        this.$set(this.localShares, this.formula.id, value)
                }
            },
            currentTotalShare: {
                get() {
                    const id = this.formula.id;
                    // has local value?
                    if (this.localTotalShares[id]) {
                        return this.localTotalShares[id];
                    }

                    if (this.defaultShares[id]) {
                        return this.defaultShares[id][0];
                    }

                    // default
                    return this.formula.defaultShare;
                },
                set(value) {
                    if (value > 0 && this.formula) {
                        this.$set(this.localTotalShares, this.formula.id, value)
                    }
                }
            },
            /**
             * Set the formula bases on the ID
             */
            formula() {
                return this.formulas.find(formula => formula.id === Number(this.data.formula))
            },
            isValid() {
                return this.value.product != null;
            }
        },
        watch: {
            /**
             * If initial days are changed check if we can update the "local" days
             */
            globalDays(days, oldDays) {
                if (this.data.days === oldDays) {
                    this.data.days = days
                }
            },
            globalDayshare(newValue, oldValue) {
                if (this.data.dayshare === oldValue) {
                    this.data.dayshare = newValue
                }
            },
            data() {
                this.$emit('input', this.data);
            }
        },
        methods: {
            formatCurrency: input => input.toFixed(2).replace('.', ','),

            fieldName(field) {
                return `${this.$parent.$parent.productName}[${this.index}][${field}]`
            },

            registerProductCreate() {
                EventBus.$once('productCreated', (data) => {
                    this.$nextTick(() => {
                        this.$set(this.data, 'product', data.id);
                    });
                });
            },

            /**
             * Parse incoming errors to see if they match this row.
             *
             * @param input
             */
            parseErrors(input) {
                for (let [key, message] of Object.entries(input)) {
                    const match = key.match(`\\[${this.index}\\]\.(.*)`);
                    if (match) {
                        this.errors[match[1]] = message;
                    }
                }
            }
        },
        created() {
            if (this.value.activeShare) {
                this.currentShare = this.value.activeShare;
            }

            if (this.value.activeTotalShare) {
                this.currentTotalShare = this.value.activeTotalShare;
            }

            EventBus.$on('formulaCreated', (data) => {
                if (this.formulaCreated) {
                    this.$set(this.data, 'formula', data.id);
                    this.formulaCreated = false
                    this.currentShare = data.currentShare;
                    this.currentTotalShare = data.defaultShare;
                    this.$forceUpdate();
                }
            })

            this.parseErrors(this.$parent.$parent.errors);
        },
        mounted() {
            $('.btn-default').tooltip()
        }
    }
</script>

<style lang="scss">
    input.default {
        color: lightgrey;
    }

    input.invalid {
        color: #a94442;
        background-color: #FFDEDE;
        border-color: #CD0A0A;
    }

    .select2-container.invalid {
        background-color: #FFDEDE;
        border-color: #CD0A0A;

        .select2-chosen {
            color: #a94442;
        }
    }

    tr.invalid >td {
        vertical-align: top !important;
    }

    tbody.rayParent input.form-control {
        /* font-family: 'Kameron', serif; */
    }
</style>
